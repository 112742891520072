<template>
  <div>
    <parser-nav />

    <v-layout row>
      <v-flex xs12 class="posts-wrap">
        <div class="posts-wrap-content ma-auto">
          <v-layout row align-center>
            <v-flex>
              <input v-model="isCheckAll" type="checkbox" @click="checkAll">
            </v-flex>

            <v-flex xs11>
              <div class="text-xs-center mb-2">
                <div class="text-xs-center">
                  <v-pagination
                    v-model="pagination.page"
                    :length="pages()"
                    :total-visible="10"
                    @input="refreshPaginationPage"
                  />
                </div>
              </div>
            </v-flex>

            <v-flex xs1 d-flex class="pa-0 mb-4 mr-3">
              <v-select
                v-model="pagination.rowsPerPage"
                :items="[10, 25, 50, 100]"
                block
                hide-details
                single-line
                @input="refreshPaginationRows"
              />
            </v-flex>
          </v-layout>
          <v-layout v-if="checkedPosts.length">
            <v-flex class="xs10">
              <v-select
                v-model="action"
                label="Actions"
                :items="bulkActionItems"
                item-text="name"
                item-value="action"
                single-line
                block
                hide-details
              />
            </v-flex>
            <v-flex class="xs2">
              <v-btn
                :disabled="isLoading || !action"
                :loading="isLoading"
                block
                class="text-none mb-4"
                color="info"
                variant="flat"
                @click="handleBulkAction"
              >
                Submit
              </v-btn>
            </v-flex>
          </v-layout>
          <v-card v-if="isLoading" class="pa-3">
            <v-progress-linear
              color="white"
              indeterminate
              rounded
              height="3"
            />
          </v-card>
          <div v-else>
            <v-card class="pa-3">
              <div v-if="posts && posts.length > 0" class="posts-list">
                <div
                  v-for="(post) in posts"
                  :key="post.id"
                  class="posts-list-item"
                >
                  <div class="post-content-top">
                    <v-layout row>
                      <v-flex xs1>
                        <input v-model="checkedPosts" type="checkbox" :value="post.id" class="checkbox-item pa-0" @change="updateCheckAll">
                      </v-flex>
                      <v-flex>
                        <v-layout align-center justify-start row class="pa-2">
                          <div class="user-link">
                            <v-avatar size="60px">
                              <v-icon class="no-avatar-icon">
                                mdi-account
                              </v-icon>
                            </v-avatar>
                            <div
                              class="title text--darken-1 ml-3 wrap-text"
                              v-text="'No data'"
                            ></div>
                          </div>
                        </v-layout>
                      </v-flex>

                      <v-flex xs6>
                        <div
                          class="text-xs-right mb-2 pb-1 v-btn--block justify-end"
                        >
                          <v-btn
                            :to="`/parser/${$route.params.name}/parser_post_list/parsed_post_create/${post.id}`"
                            color="success"
                            class="ma-0 ml-2"
                          >
                            Edit
                          </v-btn>
                          <v-btn
                            color="error"
                            class="ma-0 ml-2"
                            @click="deletePost(post.id)"
                          >
                            Delete forever
                          </v-btn>
                        </div>
                        <div
                          class="text-xs-right"
                          v-text="timePassed(post.date)"
                        ></div>
                      </v-flex>
                    </v-layout>

                    <v-layout row>
                      <v-flex v-if="post.title" xs12 class="mb-3">
                        <router-link
                          :to="`/parser/${$route.params.name}/parser_post_list/parsed_post_create/${post.id}`"
                          class="headline mb-4 wrap-text post-link"
                          v-text="post.title"
                        />
                      </v-flex>
                    </v-layout>
                  </div>

                  <div class="post-content">
                    <div v-if="post.content">
                      <div
                        v-if="post.content.type === 'image' || post.content.type === 'gif'"
                        class="post-item mb-3"
                      >
                        <div class="image-block">
                          <router-link
                            :to="`/parser/${$route.params.name}/parser_post_list/parsed_post_create/${post.id}`"
                          >
                            <img :src="post.content.url" alt="">
                          </router-link>
                        </div>
                      </div>

                      <div
                        v-if="post.content.type === 'video'"
                        class="post-item mb-3"
                      >
                        <div class="video-block">
                          <router-link
                            :to="`/parser/${$route.params.name}/parser_post_list/parsed_post_create/${post.id}`"
                          >
                            <video
                              controls
                              :src="post.content.url"
                              type="video/mp4"
                            >
                            </video>
                          </router-link>
                        </div>
                      </div>

                      <div
                        v-if="post.content.type === 'iframe'"
                        class="post-item mb-3"
                      >
                        <div class="iframe-block">
                          <router-link
                            :to="`/parser/${$route.params.name}/parser_post_list/parsed_post_create/${post.id}`"
                          >
                            <iframe
                              :src="
                                `https://www.youtube.com/embed/${youtubeUrl(post.content.url)}`
                              "
                              frameborder="0"
                              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                              allowfullscreen
                            ></iframe>
                          </router-link>
                        </div>
                      </div>
                    </div>

                    <div v-if="post.description" class="post-item mb-3">
                      <router-link
                        :to="`/parser/${$route.params.name}/parser_post_list/parsed_post_create/${post.id}`"
                        class="text-block wrap-text post-link"
                        v-text="post.description"
                      />
                    </div>
                  </div>

                  <!--                  <v-layout align-center justify-start row>-->
                  <!--                    <v-flex xs4 class="center-align">-->
                  <!--                      <v-btn-->
                  <!--                        :to="`/parser/parser_post_list/parsed_post_create/${post.id}`"-->
                  <!--                        flat-->
                  <!--                        large-->
                  <!--                        class="blue-grey&#45;&#45;text bold"-->
                  <!--                        v-text="'See Post'"-->
                  <!--                      />-->
                  <!--                    </v-flex>-->
                  <!--                  </v-layout>-->
                </div>
              </div>
              <div v-else class="posts-list">
                Not found posts
              </div>
            </v-card>
            <v-layout v-if="checkedPosts.length">
              <v-flex class="xs10">
                <v-select
                  v-model="action"
                  label="Actions"
                  :items="bulkActionItems"
                  item-text="name"
                  item-value="action"
                  single-line
                  block
                  hide-details
                />
              </v-flex>
              <v-flex class="xs2">
                <v-btn
                  :disabled="isLoading || !action"
                  :loading="isLoading"
                  block
                  class="text-none mb-4"
                  color="info"
                  variant="flat"
                  @click="handleBulkAction"
                >
                  Submit
                </v-btn>
              </v-flex>
            </v-layout>
            <v-layout row align-center>
              <v-flex xs11>
                <div class="text-xs-center mb-2">
                  <div class="text-xs-center">
                    <v-pagination
                      v-model="pagination.page"
                      :length="pages()"
                      :total-visible="10"
                      @input="refreshPaginationPage"
                    />
                  </div>
                </div>
              </v-flex>

              <v-flex xs1 d-flex class="pa-0 mb-4 mr-3">
                <v-select
                  v-model="pagination.rowsPerPage"
                  :items="[10, 25, 50, 100]"
                  block
                  hide-details
                  single-line
                  @input="refreshPaginationRows"
                />
              </v-flex>
            </v-layout>
          </div>
        </div>
      </v-flex>
    </v-layout>
    <accept-modal
      v-model="showAcceptModal"
      :post-id="postId"
      :post-type="$route.params.name"
      @accept="deleteForeverPost"
    />
  </div>
</template>

<script>
import ParserNav from "@/components/Parser/ParserNav"
import { log } from "../../../../utils/helpers"
import { getParserNeedApprovePostsRequest, bulkActions } from "../../../../api/parsedPosts"
import AcceptModal from "@/components/Modals/AcceptModal"
import { postChangeVisitedStatus } from "../../../../api/post"
import { fetchData } from "../../../../utils/response"
import moment from "moment"
import SavesPagination from "../../../../mixins/SavesPaginationMixin"
import mixinYoutubeUrl from "../../../../mixins/mixinYoutubeUrl"
import { BaseUrl } from "../../../../config"
import { deleteParsedForeverPost } from "@/api/post"

export default {
  name: "NeedApproveParsedPostsList",
  components: {
    ParserNav,
    AcceptModal,
  },
  mixins: [SavesPagination, mixinYoutubeUrl],
  data() {
    return {
      postId: null,
      deletedPost: [],
      BaseUrl: "",
      isLoading: false,
      isLoadingApprove: null,
      search: null,
      total: 0,
      pagination: {},
      posts: {},
      showAcceptModal: false,
      checkedPosts: [],
      isCheckAll: false,
      action: '',
      bulkActionItems: [
        {
          name: 'Activate with translation',
          action: 'publish-with-translation'
        },
        {
          name: 'Activate without translation',
          action: 'publish-without-translation'
        },
        {
          name: 'Delete post(s)',
          action: 'delete-posts'
        }
      ]
    }
  },
  computed: {
    bulkActionMessages () {
      return [
        {
          action: 'publish-with-translation',
          message: `${this.checkedPosts.length} post(s) with translation successfully sent to the queue`
        },
        {
          action: 'publish-without-translation',
          message: `${this.checkedPosts.length} post(s) without translation successfully sent to the queue`
        },
        {
          action: 'delete-posts',
          message: `${this.checkedPosts.length} post(s) will be deleted`
        }
      ]
    }
  },
  watch: {
    /**
     * Watch pagination changes and load more data
     */
    total() {
      this.$paginationCacheSave(
        this.pagination.page,
        this.pagination.rowsPerPage
      )
    }
  },
  mounted() {
    this.BaseUrl = BaseUrl
  },
  created() {
    this.$watch(
          () => this.$route.params,
          (toParams, previousParams) => {
            if (toParams.name !== previousParams.name) {
              this.refreshPaginationRows()
            }
          }
      )
    if (this.$route.params.status){
      this.$notificationShow(`Post is ${this.$route.params.status}!`)
    }
    const pagination = this.$paginationCacheGet()
    this.pagination.page = pagination.page
    this.pagination.rowsPerPage = pagination.per_page
    this.refresh()
    this.$bus.on("parsed-post-search-input", this.onSearchPost)
  },
  destroyed() {
    this.search = null
    this.$bus.off("parsed-post-search-input", this.onSearchPost)
  },
  methods: {
    checkAll: function() {
      this.isCheckAll = !this.isCheckAll
      this.checkedPosts = []

      if (this.isCheckAll) {
        for (let post of this.posts) {
          this.checkedPosts.push(post.id)
        }
      }
    },
    updateCheckAll: function() {
      this.isCheckAll = this.posts.length === this.checkedPosts.length
    },
    openForeverDeleteModal(id){
      this.postId = id
      this.showAcceptModal = true
    },
    async deletePost(postId) {
      let params = {
        post_id: postId,
        resource: this.$route.params.name
      }

      await deleteParsedForeverPost(params)
      this.deleteForeverPost()
    },
    deleteForeverPost(){
      this.refresh()
      this.$notificationShow(
          `The post is successfully deleted`
      )
    },
    createUrl(slug) {
      return `${this.BaseUrl}post/${slug}`
    },
    async refresh() {
      const params = {
        page: this.pagination.page,
        per_page: this.pagination.rowsPerPage,
        search: this.search,
        resource: this.$route.params.name,
      }
      this.isLoading = true
      this.posts = await getParserNeedApprovePostsRequest(params)
      // this.posts = await getNeedApprovePostsRequest(params)
        .then(response => {
          log(response)
          this.total = response.data.meta.total
          this.$bus.emit(
            "new-posts-count",
            response.data.additional.new_posts_count
          )
          return response
        })
        .then(fetchData)
        .then(data =>
          data.map(post => {
            return post
          })
        )
        .catch(this.$notificationHandleResponseError)
        .finally(() => (this.isLoading = false))
    },
    onSearchPost(searchPostValue) {
      this.search = searchPostValue
      this.refreshPaginationRows()
    },
    refreshPaginationPage() {
      this.refresh()
      this.$paginationCacheSave(
        this.pagination.page,
        this.pagination.rowsPerPage
      )
    },
    refreshPaginationRows() {
      this.pagination.page = 1
      this.refresh()
      this.$paginationCacheSave(
        this.pagination.page,
        this.pagination.rowsPerPage
      )
    },
    timePassed(create) {
      return moment(create).fromNow()
    },
    deleteCurrentPost(id) {
      let index = this.posts
        .map(function(x) {
          return x.id
        })
        .indexOf(id)
      this.posts.splice(index, 1)
      this.$notificationShow(
        "The post is declined. The user will get notification about this"
      )
    },
    postChangeVisited(id, index) {
      postChangeVisitedStatus(id)
        .then(() => {
          this.posts[index].isNew = 0
        })
        .catch(this.$notificationHandleResponseError)
    },
    pages() {
      if (this.pagination.rowsPerPage == null || this.total == null) return 0

      return Math.ceil(this.total / this.pagination.rowsPerPage)
    },
    async handleBulkAction() {
      this.isLoading = true
      let form = new FormData()

      for (let i = 0; i < this.checkedPosts.length; i++) {
        form.append("posts[]", this.checkedPosts[i])
      }

      form.append('action', this.action)
      form.append('source', this.$route.params.name)

      await bulkActions(form)
          .then(() => {
            this.$notificationShow(this.bulkActionMessages
                .find(action => action.action === this.action)
                .message
            )
          })
          .catch(this.$notificationHandleResponseError)
          .finally(() => {
            this.isLoading = false
            this.refreshPaginationPage()
          })

      this.isCheckAll = false
      this.checkedPosts = []
    }
  }
}
</script>
